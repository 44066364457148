import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

const TagItem = ({ tag }) => {
  const toTag = () => {
    navigate(`/blog/tag/${tag.slug}`)
  }

  return (
    <li>
      <div role="button" onClick={toTag} onKeyPress={toTag} tabIndex={0}>
        {tag.heading}
      </div>
    </li>
  )
}

const TagList = ({ tags }) => {
  return (
    Array.isArray(tags) &&
    tags.length > 0 && (
      <Tags>
        <p className="heading">Теги</p>
        <ul>
          {tags.map(tag => (
            <TagItem tag={tag} key={tag.slug} />
          ))}
        </ul>
      </Tags>
    )
  )
}

export default TagList

const Tags = styled.div`
  padding: 1rem var(--offset-one-side);
  .heading {
    font-size: 22px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }

  li {
    font-size: 0.8rem;
    background-color: var(--color-background);
    color: var(--color-on-background);
    cursor: pointer;
    white-space: nowrap;
    padding: 0.75rem;
    display: inline-flex;
    align-items: center;
    height: 1.75rem;
    margin-right: 0.5rem;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    &:hover {
      background-color: var(--color-primary);
      color: var(--color-on-primary);
    }
  }
`
