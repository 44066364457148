import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import LayoutBlog from "../layouts/blog"
import MetaList, { MetaItem } from "../components/meta"
import { Article } from "../components/article"
import TagList from "../components/tags"
import Image from "../components/image"
import Owner from "../components/owner"
import moment from "../util/moment"

const BlogPostPage = ({ data }) => {
  const { owner, image, category, ...post } = data.api.result.data
  const url = `/blog/${category.slug}/${post.slug}`

  const breadcrumbList = [
    { to: "/", name: "Главная" },
    { to: "/blog", name: "Статьи" },
    { to: `/blog/${category.slug}`, name: category.heading },
    { to: url, name: post.heading },
  ]

  const pageData = {
    showAside: true,
    hero: <h1 className="heading">{post.heading}</h1>,
    content: (
      <>
        <MetaList>
          <Owner
            className="mr-4"
            owner={owner}
            small={moment(post.publishedAt).fromNow()}
          />
          <MetaItem
            className="mr-4"
            heading="Обновлено"
            small={moment(post.updatetAt).fromNow()}
          />
          <MetaItem className="ml-auto mr-4" icon="eye" small={post.views} />
          <MetaItem icon="chat" small={post.commentsCount} />
        </MetaList>

        <Image type="fluid" image={image} />
        <Article dangerouslySetInnerHTML={{ __html: post.content }} />
        <TagList tags={post.tags} />
      </>
    )
  }

  return (
    <LayoutBlog breadcrumbs={breadcrumbList} backLink="/blog" page={pageData}>
      <Seo
        title={post.heading}
        description={post.heading}
        url={url}
      />
    </LayoutBlog>
  )
}

export default BlogPostPage

export const query = graphql`
  query getPostBySlug($slug: String!) {
    api {
      result: postBySlug(slug: $slug) {
        data {
          id
          title
          description
          heading
          slug
          owner {
            id
            name
            image {
              id
              url
              alt
              file {
                childImageSharp {
                  fixed(width: 36, height: 36) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          category {
            heading
            slug
          }
          tags {
            id
            heading
            slug
          }
          image {
            id
            url
            alt
            file {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          content
          status
          views
          commentsCount
          createdAt
          updatetAt
          publishedAt
        }
      }
    }
  }
`
