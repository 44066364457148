import styled from "styled-components"

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--blog-row-mt);
  flex-grow: 1;
  --article-width: calc(100% / var(--columns) * var(--blog-content-size));
`

export const Content = styled.div`
  --offset-content: calc(100% / var(--columns) * var(--content-offset-left));
  width: calc(100% - var(--offset-content));
  background: var(--color-surface);
  color: var(--color-on-surface);
  border-top-left-radius: 1rem;
  margin-left: var(--offset-content);
`

export const Article = styled.article`
  max-width: var(--article-width, 1200px);
  padding-left: var(--offset-one-side);
  padding-right: var(--offset-one-side);
  h2,
  h3,
  h4,
  h5 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-family: var(--headingfont);
    line-height: 1.15;
    font-weight: 500;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  ul,
  ol,
  p {
    font-size: 18px;
  }

  blockquote {
    padding: var(--offset-one-side);
    margin: 1em calc(var(--offset-one-side) * -1);
    background: rgba(255, 113, 0, 0.05);
    color: rgba(0, 0, 0, 0.55);
    border-left: 5px solid #ff7100;
    p {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .dark-theme && {
    blockquote {
      color: rgba(255, 255, 255, 0.55);
    }
  }
`